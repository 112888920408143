import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    primary: '#ffcc00',
    primaryDark: '#ffcc00',
    background: '#343434',

    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/weinreich-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/weinreich-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/weinreich-logo.png',

    availableProducts: ["Schadenmeldung", "smartKV", "Zustandsbericht", "Haftpflichtschaden"],
});