import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen w-screen bg-background flex flex-col" }
const _hoisted_2 = { class: "flex-grow flex overflow-hidden" }
const _hoisted_3 = { class: "flex-grow flex flex-col bg-background p-5 space-y-5 pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Topmenu = _resolveComponent("Topmenu")!
  const _component_Sidemenu = _resolveComponent("Sidemenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Topmenu),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Sidemenu),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}