import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    primary: '#f69f13',
    primaryDark: '#00498f',

    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/frigosped-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/frigosped-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/frigosped-logo.png',

    availableProducts: ["Haftpflichtschaden", "Kaskoschaden"],
});

// 00498f (blue)
// f69f13 (orange)