import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
  //Individuelle Farbwerte
  primary: '#006ec8',
  primaryDark: '#00447b',

  //Hier kommen die hinzugefügte Logos rein
  logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/salomon-logo.png',
  smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/salomon-small.png',
  loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/salomon-logo.png',

  //Hier die spezifische Produkte eintragen
  availableProducts: ["Haftpflichtschaden", "Reparaturnachweis", "Schadenmeldung", "Kaskoschaden"],
});