import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Loading from './components/Loading.vue'
import VueClickAway from "vue3-click-away"
import VueApexCharts from "vue3-apexcharts"
import ConvertReportName from "./components/ConvertReportName.vue";
import './assets/styles/base.css'
import { store } from './store'
import { registerScrollSpy } from 'vue3-scroll-spy'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { createPinia } from "pinia";

const pinia = createPinia();
const app = createApp(App)

async function init() {
  await store.dispatch('userModule/fetchCurrentUser')
  registerScrollSpy(app);

  app.use(pinia).use(store).use(router).use(VueClickAway).use(VueApexCharts).component('loading', Loading).component('convert-report-name', ConvertReportName).component('VueDatePicker', VueDatePicker).mount('#app')
}

init()