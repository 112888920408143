
import { defineComponent } from "vue";
import { Topmenu, Sidemenu } from "@/components/layout";

export default defineComponent({
  components: {
    Topmenu,
    Sidemenu,
  },
});
