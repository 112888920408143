import { InsurancePayload } from './../types/Insurance';
import { AxiosHttpClient } from "@/modules/axios_http_client";
import { ApiResponse } from "@/modules/http_client";
import { Insurance } from "@/types/Insurance";

class InsuranceService {
    private httpClient: AxiosHttpClient = new AxiosHttpClient();

    async fetchAllInsurances(): Promise<ApiResponse<Array<Insurance>, Error>> {
        const [error, response] = await this.httpClient.get<Array<Insurance>>(`/insurance/all`);
        return [response!, error];
    }

    async fetchInsurance(insuranceId: number): Promise<ApiResponse<Insurance, Error>> {
        const [error, response] = await this.httpClient.get<Insurance>(`/insurance/${insuranceId}`);
        return [response!, error];
    }

    async createInsurance(insurance: Insurance): Promise<ApiResponse<Insurance, Error>> {
        const [error, response] = await this.httpClient.post<Insurance>(`/insurance/create`, insurance);
        return [response!, error];
    }

    async updateInsurance(insuranceId: number, insurance: Insurance): Promise<ApiResponse<Array<Insurance>, Error>> {
        const [error, response] = await this.httpClient.post<Array<Insurance>>(`/insurance/update/${insuranceId}`, insurance);
        return [response!, error];
    }

    async deleteInsurance(insuranceId: number): Promise<ApiResponse<string, Error>> {
        const [error, response] = await this.httpClient.delete<string>(`/insurance/delete/${insuranceId}`);
        return [response!, error];
    }
}
export default new InsuranceService();