export class LawyerBaseData {
  id = 0;
  user_Id = 0;
  companyName = "";
  salutation = "";
  firstName = "";
  lastName = "";
  streetAndNumber = "";
  zipCode = "";
  city = "";
  phone = "";
  mail = "";
}

export class AppraiserBaseData {
  id = 0;
  user_Id = 0;
  appraiserName = "";
  company = "";
  phone = "";
  mail = "";
  displayName = "";
}

export class WorkshopBaseData {
  id = 0;
  user_Id = 0;
  companyName = "";
  streetAndNumber = "";
  zipCode = "";
  city = "";
  workshopLocation = "";
  contactName = "";
  contactPhoneNumber = "";
  contactEmail = "";
  displayName = "";
}