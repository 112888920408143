import user from "@/store/user";
import report from "@/store/report";
import vehicle from "@/store/vehicle";
import generic from "@/store/generic";
import serviceProvider from "@/store/serviceProvider";
import theme from "@/store/theme";
import toursModule from "@/store/tours";
import commissioning from "@/store/commissioning";
import insurance from "@/store/insurance";

import { createStore } from "vuex";

export type RootState = {
  userModule: any;
  title: string;
}

export const store = createStore<RootState>({
  state: () => ({
    userModule: null, 
    title: "root Module"
  }),
})

store.registerModule("userModule", user);
store.registerModule("reportModule", report);
store.registerModule("vehicleModule", vehicle);
store.registerModule("genericModule", generic);
store.registerModule("themeModule", theme);
store.registerModule("serviceProviderModule", serviceProvider);
store.registerModule("toursModule", toursModule);
store.registerModule("commissioningModule", commissioning);
store.registerModule("insuranceModule", insurance);

