import { ChangeStatusDTO, FetchReportFilesResponse, FetchReportsResponse, FetchReportStatusHistoryResponse, ForwardReportDTO, ReportFilter, UploadFileResponse } from "@/types/Report";
import { AxiosHttpClient } from "@/modules/axios_http_client";
import { ApiResponse } from "@/modules/http_client";
import { ComprehensiveDamageReport, LiabilityDamageReport, Report, SmartKVReport, StatusReport, IndividuellerKaskoSchaden, AdditionalQuestion, DamageReport, WaybillCheck } from "@/classes/report";

class ReportService {
    private httpClient: AxiosHttpClient = new AxiosHttpClient()

    async createReport(reportType: string, newReportData: Report) {
        const [error, response] = await this.httpClient.post<FetchReportsResponse>(`/sqlreports/${reportType}/createnew`, newReportData)
        return [response, error]
    }

    async fetchReport(reportFilter: ReportFilter): Promise<ApiResponse<FetchReportsResponse, Error>> {
        const [error, report] = await this.httpClient.get<FetchReportsResponse>(`/sqlreports/search?${this.getQueryString(reportFilter)}`);
        return [report!, error]
    }

    //Fetch data from Azure Table Storage
    async fetchPreCheckReports(): Promise<ApiResponse<FetchReportsResponse, Error>> {
        const [error, reports] = await this.httpClient.get<FetchReportsResponse>(`/sqlreports/getPreCheckReports`);
        return [reports!, error]
    }

    //Fetch one report from Azure Table Storage
    async fetchPreCheckReportByRowKeyId(reportId: string): Promise<ApiResponse<FetchReportsResponse, Error>> {
        const [error, report] = await this.httpClient.get<FetchReportsResponse>(`/sqlreports/getPreCheckReport/${reportId}`);
        return [report!, error]
    }

    async filterPreCheckReportsByLicenseNumber(licenseNumber: string): Promise<ApiResponse<FetchReportsResponse, Error>> {
        const [error, reports] = await this.httpClient.get<FetchReportsResponse>(`/sqlreports/filterPreCheckReport/${licenseNumber}`);
        return [reports!, error]
    }

    private getQueryString(reportFilter: ReportFilter): string {

        const searchObjectString = encodeURIComponent(JSON.stringify({
            Name: reportFilter.searchObject.name,
            Values: reportFilter.searchObject.values
          }));

        let search = "PageId=" + reportFilter.page +
            "&SearchText=" + reportFilter.search +
            "&SearchObject=" + searchObjectString +
            "&ReportTypeTable=" + reportFilter.report_type_table +
            "&Status=" + reportFilter.status +
            "&Organization=" + reportFilter.organization +
            "&HasDamage=" + reportFilter.hasDamage;

        if (reportFilter.date_from) {
            search += "&DateFrom=" + reportFilter.date_from;
        }

        if (reportFilter.date_to) {
            search += "&DateTo=" + reportFilter.date_to;
        }

        return search;
    }

    async updateReport(reportType: string, updatedReportData: Report): Promise<ApiResponse<string, Error>> {
        const jsonWithNull = JSON.stringify(updatedReportData).replaceAll('""', 'null');
        const [error, status] = await this.httpClient.post<string>(`/sqlreports/${reportType}/update`, jsonWithNull)
        return [status!, error]
    }

    async fetchReportStatusHistory(reportId: number): Promise<ApiResponse<FetchReportStatusHistoryResponse[], Error>> {
        const [error, response] = await this.httpClient.get<FetchReportStatusHistoryResponse[]>(`/sqlreports/GetStatusHistoryForReportId/${reportId}`)
        return [response!, error]
    }

    async fetchFilesForReport(reportId: number): Promise<ApiResponse<Array<FetchReportFilesResponse>, Error>> {
        const [error, response] = await this.httpClient.get<Array<FetchReportFilesResponse>>(`/sqlreports/${reportId}/getFiles`)
        return [response!, error]
    }

    async uploadFileForReport(reportId: number, data: FormData): Promise<ApiResponse<UploadFileResponse, Error>> {
        const [error, response] = await this.httpClient.upload<UploadFileResponse>(`/sqlreports/UploadFileForId/${reportId}`, data);
        return [response!, error]
    }

    async updateStatusForReport(reportId: number, data: ChangeStatusDTO) {
        const [error, response] = await this.httpClient.post<string>(`/sqlreports/updateStatus/${reportId}`, data);
        return [response!, error];
    }

    async getReportById(reportId: number): Promise<ApiResponse<LiabilityDamageReport | ComprehensiveDamageReport | StatusReport | SmartKVReport | IndividuellerKaskoSchaden | DamageReport, Error>> {
        const [error, response] = await this.httpClient.get<LiabilityDamageReport | ComprehensiveDamageReport | StatusReport | SmartKVReport | IndividuellerKaskoSchaden | DamageReport>(`/sqlreports/GetReportForReportId/${reportId}`)
        return [response!, error]
    }

    async getLastFiveReports(): Promise<ApiResponse<Array<Report>, Error>> {
        const [error, response] = await this.httpClient.get<Array<Report>>(`/sqlreports/GetRecentReports`);
        return [response!, error];
    }

    async getReportDownload(reportId: string): Promise<ApiResponse<Blob, Error>> {
        const [error, response] = await this.httpClient.getZip<Blob>(`/sqlreports/downloadzip/${reportId}`);
        return [response!, error];
    }

    async postAddAdditionalQuestion(additionalQuestion: any) {
        const [error, response] = await this.httpClient.post(`/sqlreports/AddAdditionalQuestion`, additionalQuestion)
        return [response, error]
    }

    async postMarkAdditionalQuestionWithIdAsDone(id: string) {
        const [error, response] = await this.httpClient.post(`/sqlreports/MarkAdditionalQuestionWithIdAsDone/${id}`, "")
        return [response, error]
    }

    async getAdditionalQuestionsForReportId(report_Id: string): Promise<ApiResponse<Array<AdditionalQuestion>, Error>> {
        const [error, response] = await this.httpClient.get<Array<AdditionalQuestion>>(`/sqlreports/GetAdditionalQuestionsForReportId/${report_Id}`);
        return [response!, error];
    }

    async forwardReportAsMail(reportId: number, email: string, senderEmail: string, subject: string): Promise<ApiResponse<boolean, Error>> {
        const dto = {
            "reportId": reportId,
            "email": email,
            "senderEmail": senderEmail,
            "subject": subject,
        } as ForwardReportDTO;

        const [error, response] = await this.httpClient.post<boolean>(`/sqlreports/ForwardReportToMail`, dto);
        return [response!, error]
    }

    async getAssociatedWaybillChecks(reportNumber: number, waybillNumber: number): Promise<ApiResponse<Array<WaybillCheck>, Error>> {
        const [error, response] = await this.httpClient.get<Array<WaybillCheck>>(`/sqlreports/GetAssociatedWaybillChecks/${reportNumber}/${waybillNumber}`);
        return [response!, error];
    }

    async downloadReportsResultsCSV(reportFilter: ReportFilter): Promise<ApiResponse<Blob, Error>> {
        const [error, response] = await this.httpClient.downloadFile<Blob>(`/sqlreports/download/csv?${this.getQueryString(reportFilter)}`)
        return [response!, error]
    }

    async hsExport(): Promise<ApiResponse<Blob, Error>> {
        console.log("hsExport");
        // const [error, response] = await this.httpClient.get<Blob>(`/sqlreports/hsexport`);
        const [error, response] = await this.httpClient.downloadXLXSFile<Blob>(`/sqlreports/hsexport`, 'HSExport.xlsx');

        console.log(response);
        return [response!, error];
    }
}

export default new ReportService()
