
import { defineComponent } from "vue";
export const convertedName = (reportType: string) => {
  if (!reportType) return "Unbekannt";

  if (reportType.toLowerCase() == "liabilitydamage") {
    return "Haftpflichtschaden";
  } else if (reportType.toLowerCase() == "individuellerkaskoschaden") {
    return "Kaskobericht";
  } else if (reportType.toLowerCase() == "comprehensivedamage") {
    return "Kaskoschaden";
  } else if (reportType.toLowerCase() == "smartkv") {
    return "smartKV";
  } else if (reportType.toLowerCase() == "onlinegutachten") {
    return "Online Gutachten";
  } else if (reportType.toLowerCase() == "onlinekv") {
    return "OnlineKV";
  } else if (reportType.toLowerCase() == "damagereport") {
    return "Schadenmeldung";
  } else if (reportType.toLowerCase() == "repairconfirmation") {
    return "Reparaturnachweis";
  } else if (reportType.toLowerCase() == "floorcheck") {
    return "FloorCheckGo";
  } else if (reportType.toLowerCase() == "waybillcheck") {
    return "Transportabnahme";
  } else if (reportType.toLowerCase() == "statusreport") {
    return "Zustandsbericht";
  } else if (reportType.toLowerCase() == "precheckgo") {
    return "PreCheckGo";
  }
  // return "Unbekannt";
  return reportType;
};

export default defineComponent({
  name: "convert-report-name",
  props: {
    reportType: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { convertedName };
  },
});
