
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  props: {
    activateOnChange: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    }
  },
  emits: ["setText"],
  setup(props, { emit }) {
    const textLocal = ref(props.text);

    watch(() => props.text, () => {
      textLocal.value = props.text
    })

    const onChangeInput = (e: Event) => {
      emit("setText", textLocal.value)
    }

    const triggerText = () => {
      emit("setText", textLocal.value)
    }

    watch(() => textLocal.value, () => {
      if(props.activateOnChange && props.text == "") {
        emit("setText", textLocal.value)
      }
    })

    return { textLocal, onChangeInput, triggerText }
  }
});
