import UserService from "@/services/UserService"
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { HttpClient, HttpClientResponse } from "./http_client"

export class AxiosHttpClient implements HttpClient {
  private axiosInstance: AxiosInstance

  constructor() {
    let baseUrl = "http://localhost:61309/api";

    if (document.location.origin.includes('servicenavigator.digital') || document.location.origin.includes('gentle-wave')) {
      baseUrl = "https://myschaden24-api-2021-staging.azurewebsites.net/api";
    } else {
      baseUrl = "https://myschaden24-api-2021-staging-service-navigator-staging.azurewebsites.net/api";
      // baseUrl = "https://myschaden24-api-2021-staging.azurewebsites.net/api";



      // baseUrl = "http://192.168.178.27:7440/api";



      // baseUrl = "http://172.20.10.2:7440/api"
      // baseUrl = "http://192.168.178.51:7440/api";
    }

    this.axiosInstance = axios.create({
      baseURL: baseUrl,
    })

    this.axiosInstance.interceptors.request.use(
      request => {
        const token = UserService.getToken()
        request.headers['Accept'] = 'application/json'
        request.headers['Content-Type'] = 'application/json; charset=utf-8'
        request.headers['Authorization'] = `Bearer ${token}`
        return request
      },
      error => {
        return Promise.reject(error)
      }
    )
  }

  clearQuoteApiClient(): AxiosInstance {
    const client = new AxiosHttpClient()

    let apiBaseUrl = "https://euserver.clearquote.io";
    if (document.location.origin.includes('servicenavigator.digital') || document.location.origin.includes('gentle-wave')) {
      //TODO(ngjoni): add prod url
      apiBaseUrl = "";
    }
    else if (document.location.origin.includes('ambitious-tree-01686ba03.azurestaticapps.net')) {
      apiBaseUrl = "https://euserver.clearquote.io";
    }

    client.axiosInstance = axios.create({
      baseURL: apiBaseUrl,
    })

    const token = '';
    client.axiosInstance.interceptors.request.use(
      request => {
        request.headers['Authorization'] = `Bearer ${token}`
        
        return request
      }
    ,error => {
      return Promise.reject(error)
    })
    return client.axiosInstance
  }

  // create a new axios instance
  secondApiClient(branding = "schlienztours"): AxiosInstance{
    const client = new AxiosHttpClient()

    let apiBaseUrl = "https://stg.ix.yucon.digital/api/v1";
    if (document.location.origin.includes('servicenavigator.digital') || document.location.origin.includes('gentle-wave')) {
      //TODO(ngjoni): add prod url
      apiBaseUrl = "";
    }
    else if (document.location.origin.includes('ambitious-tree-01686ba03.azurestaticapps.net')) {
      apiBaseUrl = "https://stg.ix.yucon.digital/api/v1";
    }
    // else {
    //   apiBaseUrl = "http://localhost:8080/api/v1";
    // }
    //[DEV] client secret
    // let clientSecret = "qVU1BqA5TPziU6v";
    //[STG] client secret
    // if (apiBaseUrl.includes('stg.ix.yucon.digital')) {
    //   clientSecret = "r8vsKHZr7696y4ZFMUFP79e5TDGHEHa4";
    //  }
    client.axiosInstance = axios.create({
      baseURL: apiBaseUrl,
    })
    client.axiosInstance.interceptors.request.use(
      request => {
        request.headers['clientsecret'] = "qVU1BqA5TPziU6v"
        request.headers['branding'] = branding
        return request
      }
    ,error => {
      return Promise.reject(error)
    })
    return client.axiosInstance
  }

  async get<T = unknown>(path: string, instance: AxiosInstance = this.axiosInstance): Promise<HttpClientResponse<T>> {
    try {
      const { data } = await instance.get<T>(path)
      return [null, data]
    } catch (error) {
      return [error]
    }
  }
  async post<T = unknown>(path: string, payload: any, instance: AxiosInstance = this.axiosInstance): Promise<HttpClientResponse<T>> {
    try {
      const { data } = await instance.post<T>(path, payload)
      return [null, data]
    } catch (error) {
      return [error]
    }
  }

  async upload<T = unknown>(path: string, payload: FormData, instance: AxiosInstance = this.axiosInstance): Promise<HttpClientResponse<T>> {
    const config = {
      headers: {
        'Content-Type': `multipart/form-data`
      }
    };

    try {
      const { data } = await instance.post<T>(path, payload, config)
      return [null, data]
    } catch (error) {
      return [error]
    }
  }

  async getZip<T = unknown>(path: string): Promise<HttpClientResponse<T>> {

    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': `application/zip`,
        'Accept': 'application/zip',
      }
    };

    try {
      const { data } = await this.axiosInstance.get<T>(path, config)
      return [null, data]
    } catch (error) {
      return [error]
    }
  }

  async downloadFile<T = unknown>(path: string): Promise<HttpClientResponse<T>> {

    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': `text/csv`,
        'Accept': `text/csv`,
      }
    };

    try {
      const { data } = await this.axiosInstance.get<T>(path, config)
      return [null, data]
    } catch (error) {
      return [error]
    }
  }

  async downloadXLXSFile<T = unknown>(path: string, filename: string): Promise<HttpClientResponse<T>> {
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    };

    try {
      const { data } = await this.axiosInstance.get<T>(path, config);
      return [null, data as T];
    } catch (error) {
      return [error];
    }
  }

  

  async put<T = unknown>(path: string): Promise<HttpClientResponse<T>> {
    throw new Error("Method not implemented.");
  }
  async patch<T = unknown>(path: string): Promise<HttpClientResponse<T>> {
    throw new Error("Method not implemented.");
  }
  async delete<T = unknown>(path: string): Promise<HttpClientResponse<T>> {
    try {
      const { data } = await this.axiosInstance.delete<T>(path)
      return [null, data]
    } catch (error) {
      return [error]
    }
  }
}
