import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    //Individuelle Farbwerte
    primary: '#ffcc00',
    primaryDark: '#ffcc00',

    //Hier kommen die hinzugefügte Logos rein
    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/claimore-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/claimore-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/claimore-logo.png',

    //Hier die spezifische Produkte eintragen
    availableProducts: ["Haftpflichtschaden", "Zustandsbericht", "smartKV", "Schadenmeldung", "Reparaturnachweis"],
});