
import {
  defineComponent,
  ref,
  watch,
  computed,
  watchEffect,
  onMounted,
} from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import SearchField from "@/components/SearchField.vue";
import moment from "moment";

interface DateRange {
  start: string;
  end: string;
}

export type DropdownData = {
  id: string | number;
  name?: string;
  data?: DropdownData[];
  nameEN?: string;
  nameDE?: string;
  onClick?: () => Promise<void>;
};

export default defineComponent({
  props: {
    hasCalendar: {
      type: Boolean,
      default: false,
    },
    hasCalendarRange: {
      type: Boolean,
      default: false,
    },
    calendarOptions: {
      type: Object,
    },
    preSelectedDate: {
      type: String || Object,
    },
    items: {
      type: Object as () => DropdownData[],
      required: true,
    },
    preSelectedItemId: {
      type: [Number, String],
      required: false,
    },
    preSelectedItemName: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      default: "grey",
    },
    highlightColor: {
      type: String,
      default: "red",
    },
    hasFilterInput: {
      type: Boolean,
      default: false,
    },
    iconSrc: {
      type: String,
      default: "",
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultName: {
      type: String,
      default: "Alle",
    },
    defaultId: {
      type: String,
      default: "-1",
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  components: { SearchField },
  emits: ["setSelected", "setSelectedDate", "setResetFalse"],
  setup(props, { emit }) {
    const selectedVuePickerDate = ref();

    const searchFilter = ref("");
    const filteredItems = computed(() => {
      if (props.items != undefined && searchFilter.value != "") {
        return props.items.filter(
          (r) =>
            (r.name != null &&
              r.name
                .toLowerCase()
                .includes(searchFilter.value.toLowerCase())) ||
            (r.nameDE != null &&
              r.nameDE
                .toLowerCase()
                .includes(searchFilter.value.toLowerCase())) ||
            (r.nameEN != null &&
              r.nameEN.toLowerCase().includes(searchFilter.value.toLowerCase()))
        );
      } else return props.items;
    });

    const isMenuOpen = ref<boolean>(false);

    const selectedDate = ref<string | DateRange | undefined>(
      props.preSelectedDate
    );
    const selectedItem = ref<DropdownData>({
      id: props.defaultId,
      name: props.defaultName,
    });
    const selectedSubMenuItem = ref<DropdownData>({
      id: "",
      name: "",
    });
    const preSelectedItemNameVar = ref(props.preSelectedItemName);
    const preSelectedItemIdVar = ref(props.preSelectedItemId);

    const onClickAway = () => {
      searchFilter.value = "";
      isMenuOpen.value = false;
    };

    watch(
      () => selectedDate.value,
      () => {
        if (
          props.calendarOptions != undefined &&
          props.calendarOptions.isRange
        ) {
          const data = selectedDate.value as DateRange;
          if (data.start != "" && data.end != "") {
            //Wenn der ausgewählte Tag derselbe ist und es keine Uhrzeitauswahl gibt
            if (
              moment(data.start).format("YYYY-MM-DD") ==
                moment(data.end).format("YYYY-MM-DD") &&
              props.calendarOptions.mode != "dateTime"
            ) {
              emit("setSelectedDate", {
                start: moment(data.start)
                  .hour(0)
                  .minute(0)
                  .seconds(0)
                  .format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(data.end)
                  .hour(23)
                  .minute(59)
                  .seconds(59)
                  .format("YYYY-MM-DDTHH:mm:ss"),
              });
            } else {
              emit("setSelectedDate", {
                start: moment(data.start).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(data.end).format("YYYY-MM-DDTHH:mm:ss"),
              });
            }
          }
        } else {
          let data = "";
          selectedDate.value == ""
            ? (data = "")
            : (data = moment(selectedDate.value as string).format(
                "YYYY-MM-DDTHH:mm:ss"
              ));
          emit("setSelectedDate", data);
        }
        isMenuOpen.value = false;
      },
      { deep: true }
    );

    watch(
      () => props.reset,
      () => {
        if (props.reset == true) {
          selectedItem.value = {
            id: "-1",
            name: props.defaultName,
          };
          selectedSubMenuItem.value = {
            id: "",
            name: "",
          };
          props.calendarOptions != undefined && props.calendarOptions.isRange
            ? (selectedDate.value = {
                start: "",
                end: "",
              })
            : (selectedDate.value = undefined);
          emit("setResetFalse");
        }
      }
    );

    const selectTodayDate = () => {
      props.calendarOptions != undefined && props.calendarOptions.isRange
        ? (selectedDate.value = {
            start: moment("00", "HH").format("YYYY-MM-DDTHH:mm:ss"),
            end: moment("24", "HH").format("YYYY-MM-DDTHH:mm:ss"),
          })
        : (selectedDate.value = moment("00", "HH").format(
            "YYYY-MM-DDTHH:mm:ss"
          ));
    };

    const selectItem = async (item: DropdownData) => {
      if (props.isMenu && item.onClick) {
        await item.onClick!();
        return;
      }

      if (item.data != null) {
        selectedItem.value.id != item.id
          ? (selectedItem.value = item)
          : (selectedItem.value = { name: "", id: "" });
      } else {
        emit("setSelected", item);
        searchFilter.value = "";
        selectedItem.value = item;
        selectedSubMenuItem.value = { name: "", id: "" };
        isMenuOpen.value = false;
      }
    };

    const selectSubMenuItem = (item: DropdownData) => {
      emit("setSelected", item);
      searchFilter.value = "";
      selectedSubMenuItem.value = item;
      isMenuOpen.value = false;
    };

    const checkPreSelectedItem = () => {
      if (
        preSelectedItemIdVar.value != undefined ||
        preSelectedItemNameVar.value != undefined
      ) {
        for (const [index, item] of props.items.entries()) {
          // console.log("preSelectedItemIdVar: ", preSelectedItemIdVar.value);
          // console.log("preSelectedItemNameVar: ", preSelectedItemNameVar.value);
          // console.log("----------------------------------------------");
          // console.log("item: ", item);
          // console.log("item.id: ", item.id);
          // console.log("item.name: ", item.name);
          // console.log(" ");
          // console.log(" ");

          if (
            (item.id !== undefined &&
              item.id.toString() == preSelectedItemIdVar.value?.toString()) ||
            item.name == preSelectedItemNameVar.value
          ) {
            selectedItem.value = item;
            break;
          }
          if (item.data != null) {
            for (const [index, subItem] of (item as any).entries()) {
              if (
                subItem.id.toString() ==
                  preSelectedItemIdVar.value?.toString() ||
                subItem.name == preSelectedItemNameVar.value
              ) {
                selectedItem.value = item;
                selectedSubMenuItem.value = subItem;
                break;
              }
            }
          }
        }
      }
    };

    watchEffect(() => {
      preSelectedItemNameVar.value = props.preSelectedItemName;
      preSelectedItemIdVar.value = props.preSelectedItemId;
      checkPreSelectedItem();
    });

    checkPreSelectedItem();

    const checkType = (data: any, type: any) => {
      return data instanceof type;
    };

    const setSearchFilter = async (value: string) => {
      searchFilter.value = value;
    };

    const getBackgroundColor = (strength?: number) => {
      if (props.backgroundColor == "grey") {
        return "bg-backgroundGrey";
      } else {
        return (
          "bg-" +
          props.backgroundColor +
          (strength != null ? "-" + strength : "")
        );
      }
    };

    const getHighlightColor = (dark?: boolean, strength?: number) => {
      if (props.highlightColor == "red") {
        if (dark) {
          return "bg-primary-dark";
        } else {
          ("bg-primary");
        }
      } else {
        return (
          "bg-" +
          props.highlightColor +
          (strength != null ? "-" + strength : "")
        );
      }
    };

    const formatDate = (date: string, format: string) => {
      if (date == "") {
        return "Kein Datum";
      } else {
        return moment(date).format(format);
      }
    };

    const resetDropdown = (id: number, name: string) => {
      selectedItem.value = {
        id: id,
        name: name,
      };
    };

    const handleSelectedDate = (date: any) => {
      if (!props.calendarOptions?.isRange) {
        emit("setSelectedDate", date);
        selectedDate.value = date;
        return;
      }
      selectedVuePickerDate.value = date;

      const formattedDate = {
        start: date[0],
        end: date[1],
      };

      emit("setSelectedDate", formattedDate);
      selectedDate.value = formattedDate;
    };

    return {
      filteredItems,
      isMenuOpen,
      onClickAway,
      selectedDate,
      selectedItem,
      selectedSubMenuItem,
      selectItem,
      selectSubMenuItem,
      checkType,
      setSearchFilter,
      getBackgroundColor,
      getHighlightColor,
      formatDate,
      selectTodayDate,
      resetDropdown,
      handleSelectedDate,
      selectedVuePickerDate,
    };
  },
});
