import InsuranceService from "@/services/InsuranceService";
import { Insurance, InsurancePayload } from "@/types/Insurance";
import { ActionContext, Module } from "vuex";
import { RootState } from ".";

export type CreateInsuranceState = {
    message: string;
    result: boolean;
    loading: boolean;
}

export type InsuranceState = {
    loading: boolean;
    insurances: Array<Insurance>,
    selectedInsurance?: Insurance,
    createInsuranceState: CreateInsuranceState,
    readonly: boolean,
    onChange: boolean,
}

const insurance: Module<InsuranceState, RootState> = {
    namespaced: true,
    state: () => ({
        insurances: [],
        loading: false,
        readonly: false,
        onChange: false,
        selectedInsurance: {
            id: 0,
        } as Insurance,
        createInsuranceState: {
            message: '',
            result: false,
            loading: false,
        },
    }),
    getters: {
        insurances: (state: InsuranceState) => state.insurances,
        loading: (state: InsuranceState) => state.loading,
        insurancesListByNameAndId(state: InsuranceState) {
            const result = state.insurances.map((e) => {
                return {
                    name: e.displayName,
                    id: e.id
                }
            }).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;

                return 0;
            })
            result.splice(0, 0, { name: 'Versicherung auswählen', id: -1 })
            return result
        },
        insuranceById: (state: InsuranceState) => (id: number) => {
            return state.insurances.find(i => i.id == id);
        },
        initSelectedInsuranceId: (state: InsuranceState) => state.selectedInsurance!.id

        // insuranceById: (state: InsuranceState) => sstate.selectedInsurance?.id,

    },
    mutations: {
        toggleLoading: (state: InsuranceState) => {
            state.loading = !state.loading
        },
        setSelectedInsurance: (state: InsuranceState, id: number) => {
            if (id == -1) {
                state.readonly = false;
                state.selectedInsurance = undefined;
            }
            else {
                state.selectedInsurance = state.insurances.find(insurance => insurance.id == id);
                state.readonly = true;
            }
        },
    },
    actions: {
        async initModule(context: ActionContext<InsuranceState, RootState>, insuranceId: number) {
            context.commit('toggleLoading')

            const [response, error] = await InsuranceService.fetchAllInsurances();

            context.commit('toggleLoading')

            if (!error) {
                context.state.insurances = response;
            }
        },
        createInsurance: async (context: ActionContext<InsuranceState, RootState>, options: InsurancePayload) => {
            context.state.createInsuranceState.loading = true;

            const payload = {
                id: 0,
                branding_Id: 0,
                displayName: options.displayName,
                name: options.opponentInsuranceName,
                insuranceContactFirstName: options.opponentInsuranceContactFirstName,
                insuranceContactLastName: options.opponentInsuranceContactLastName,
                insuranceStreetAndNumber: options.opponentInsuranceStreetAndNumber,
                insuranceZipCode: options.opponentInsuranceZipCode,
                insuranceCity: options.opponentInsuranceCity,
                insurancePhone: options.opponentInsurancePhone,
                insuranceMail: options.opponentInsuranceMail,
                insuranceNumber: options.opponentInsuranceNumber,
            } as Insurance;

            if (payload.displayName == '') { 
                context.state.createInsuranceState.message = 'Anzeigename darf nicht leer sein!';
                context.state.createInsuranceState.result = false
              
            }
            else {
                const [insurance, error] = await InsuranceService.createInsurance(payload);
                if (error) {
                    context.state.createInsuranceState.message = 'Es ist ein Fehler aufgetreten.'
                    context.state.createInsuranceState.result = false
                } else {
                    context.state.createInsuranceState.message = 'Die Versicherung wurde erfolgreich erstellt'
                    context.state.createInsuranceState.result = true

                    context.state.insurances.push(insurance);
                }
            }



      

            context.state.createInsuranceState.loading = false;
        },
        reset(context: ActionContext<InsuranceState, RootState>) {
            context.state.selectedInsurance = undefined;

        },
        async deleteInsurance(context: ActionContext<InsuranceState, RootState>, id: number) {
            const [_, error] = await InsuranceService.deleteInsurance(id);

            if (!error) {
                const insurance = context.state.insurances.find(i => i.id == id);
                if (insurance) {
                    const index = context.state.insurances.indexOf(insurance);
                    context.state.insurances.splice(index, 1);
                }
                await context.dispatch("genericModule/triggerAlert", {
                    alertMessage: `Die Versicherung wurde erfolgreich gelöscht!`,
                    alertBadgeText: "disabled",
                    alertColor: "green",
                    alertCloseTimeInMs: 2500,
                }, {root: true});
            } else {
                await context.dispatch("genericModule/triggerAlert", {
                    alertMessage: "Leider ist ein Fehler aufgetreten!",
                    alertBadgeText: "disabled",
                    alertColor: "green",
                    alertCloseTimeInMs: 2500,
                }, {root: true});
            }
        }
    }
}

export default insurance;