import { LawyerBaseData, AppraiserBaseData, WorkshopBaseData } from "@/classes/serviceProvider";
import { AxiosHttpClient } from "@/modules/axios_http_client";
import { ApiResponse } from "@/modules/http_client";

class ServiceProviderService {
    private httpClient: AxiosHttpClient = new AxiosHttpClient()

    async getAppraiserById(id: number): Promise<ApiResponse<AppraiserBaseData, Error>> {
        const [error, user] = await this.httpClient.get<AppraiserBaseData>(`/userhandling/GetAppraiserBaseDataById/${id}`)
        return [user!, error]
    }
    async getLawyerById(id: number): Promise<ApiResponse<LawyerBaseData, Error>> {
        const [error, user] = await this.httpClient.get<LawyerBaseData>(`/userhandling/GetLawyerBaseDataById/${id}`)
        return [user!, error]
    }
    async getWorkshopById(id: number): Promise<ApiResponse<WorkshopBaseData, Error>> {
        const [error, user] = await this.httpClient.get<WorkshopBaseData>(`/userhandling/GetWorkshopBaseDataById/${id}`)
        return [user!, error]
    }
    async getAllAppraiserBaseData(): Promise<ApiResponse<AppraiserBaseData[], Error>> {
        const [error, user] = await this.httpClient.get<AppraiserBaseData[]>(`/userhandling/GetAllAppraiserBaseData`)
        return [user!, error]
    }
    async getAllLawyerBaseData(): Promise<ApiResponse<LawyerBaseData[], Error>> {
        const [error, user] = await this.httpClient.get<LawyerBaseData[]>(`/userhandling/GetAllLawyerBaseData`)
        return [user!, error]
    }
    async getAllWorkshopBaseData(): Promise<ApiResponse<WorkshopBaseData[], Error>> {
        const [error, user] = await this.httpClient.get<WorkshopBaseData[]>(`/userhandling/GetAllWorkshopBaseData`)
        return [user!, error]
    }
}

export default new ServiceProviderService()