import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { store } from "@/store"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ "../views/ReportsPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/precheckreports',
    name: 'precheckreports',
    component: () => import(/* webpackChunkName: "precheckreports" */ "../views/PreCheckReportsPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/report/:id/precheck/overview',
    name: 'precheckoverview',
    component: () => import(/* webpackChunkName: "precheckoverview" */ '../views/PreCheckReportDetailPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tours',
    name: 'tours',
    component: () => import("../views/ToursReportsPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/objects/manage',
    name: 'objects',
    component: () => import(/* webpackChunkName: "reports" */ "../views/CreateObjectPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/statusreportdetail/:id',
    name: 'statusReport',
    component: () => import(/* webpackChunkName: "report" */ "../views/StatusReportDetailPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/kaskoreportdetail/:id',
    name: 'kaskoReport',
    component: () => import(/* webpackChunkName: "report" */ "../views/KaskoReportDetailPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/smartkvreportdetail/:id',
    name: 'smartKVReport',
    component: () => import(/* webpackChunkName: "report" */ "../views/SmartKVReportDetailPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/waybillcheck/:id',
    name: 'waybillReport',
    component: () => import(/* webpackChunkName: "report" */ "../views/WaybillCheckDetailPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/damagereportdetail/:id',
    name: 'damageReport',
    component: () => import(/* webpackChunkName: "report" */ "../views/DamageReportDetailPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/repairconfirmationreportdetail/:id',
    name: 'repairConfirmationReport',
    component: () => import(/* webpackChunkName: "report" */ "../views/RepairConfirmationReportDetailPage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: '/floorcheckreportdetail/:id',
    name: 'floorCheckReport',
    component: () => import(/* webpackChunkName: "report" */ "../views/FloorCheckReportDetailPage.vue"),
  },
  {
    path: '/report/create',
    name: 'createReport',
    component: () => import(/* webpackChunkName: "createReport" */ '../views/CreateReportPage.vue'),
    meta: { requiresAuth: true, requiredRoles: [1, 2] }
  },
  {
    path: '/report/:id/:stepNum?',
    name: 'reportProcess',
    component: () => import(/* webpackChunkName: "reportProcess" */ '../views/StepperPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report/:id/comprehensive/overview',
    name: 'comprehensiveOverview',
    component: () => import(/* webpackChunkName: "reportOverview" */ '../components/report-stepper/comprehensive-damage/ReportOverview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report/:id/liability/overview',
    name: 'liabilityOverview',
    component: () => import(/* webpackChunkName: "reportOverview" */ '../components/report-stepper/liability-damage/ReportOverview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report/:id/online-gutachten/overview',
    name: 'onlineGutachtenOverview',
    component: () => import(/* webpackChunkName: "reportOverview" */ '../components/report-stepper/online-gutachten/ReportOverview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report/:id/online-kv/overview',
    name: 'onlineKvOverview',
    component: () => import(/* webpackChunkName: "reportOverview" */ '../components/report-stepper/online-kv/ReportOverview.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users/manage',
    name: 'manageUsers',
    component: () => import(/* webpackChunkName: "manageUsers" */ '../views/ManageUsers.vue'),
    meta: { requiresAuth: true, requiredRoles: [1, 2] }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report/:id/departure-check/overview',
    name: 'departureCheckOverview',
    component: () => import(/* webpackChunkName: "reportOverview" */ '../views/ToursReportDetailPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/ai/clearquote',
    name: 'clearquote',
    component: () => import(/* webpackChunkName: "reports" */ "../views/ClearQuotePage.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/:pathMatch(.*)*", name: '404', component: () => import(/* webpackChunkName: "404" */ "../views/NotFound.vue"), meta: { requiresAuth: false },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const isSignedIn = await store.getters['userModule/isSignedIn']

  if (!isSignedIn && to.meta.requiresAuth) {
    next({ name: 'login' })
  } else {
    const userRole = store.getters['userModule/userRole'];

    if (to.meta.requiredRoles) {
      if ((to.meta.requiredRoles as Array<number>).includes(userRole))
        next();
      else
        next({ name: '404' });
    }
    else {
      next();
    }
  }
})

export default router
