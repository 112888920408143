import { Module, ActionContext } from "vuex";
import { RootState, store } from ".";

export type GenericState = {
  [key: string]: any;
  showAlert: boolean,
  alertMessage: string,
  alertBadgeText: string,
  alertColor: string
  alertSize: string
}

type alertOptions = {
  alertMessage: string,
  alertBadgeText: string,
  alertColor: string,
  alertSize: string
  alertCloseTimeInMs: number,
}

const generic: Module<GenericState, RootState> = {
  namespaced: true,
  state: () => ({
    showAlert: false,
    alertMessage: "",
    alertBadgeText: "",
    alertColor: "",
    alertSize: "",
  }),
  getters: {

  },
  mutations: {
    setShowAlert(state: GenericState, value: boolean) {
      state.showAlert = value;
    },
    setAlertOptions(state: GenericState, options: alertOptions) {
      state.alertMessage = options.alertMessage;
      state.alertColor = options.alertColor;
      state.alertBadgeText = options.alertBadgeText;
      state.alertSize = options.alertSize;
    },
  },
  actions: {
    async triggerAlert(context: ActionContext<GenericState, RootState>, alertOptions: alertOptions) {
      if (!context.state.showAlert) {
        context.commit("setAlertOptions", alertOptions)
        context.commit("setShowAlert", true)
        setTimeout(function () {
          if (context.state.showAlert) {
            context.commit("setShowAlert", false)
          }
        }, alertOptions.alertCloseTimeInMs);
      }
    },
  }
}

export default generic;
