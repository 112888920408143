
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import GenericDropdown from "@/components/GenericDropdown.vue";
import { applyTheme } from "@/themes/utils";
import { DEFAULT_THEME } from "@/themes";
export default defineComponent({
  components: {
    GenericDropdown,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const dropdownOpen = ref(false);

    const goTo = async (item: any) => {
      await router.push(item.id);
    };

    const email = computed(() => store.getters["userModule/email"]);

    const onClickAway = () => (dropdownOpen.value = false);

    const onLogout = async () => {
      await store.dispatch("userModule/logout");
      await router.push({ name: "login" });
      store.dispatch("themeModule/applyNewTheme", DEFAULT_THEME);
    };

    const getWindowLocation = () => {
      return window.location.origin;
    };

    // const getBrandingLogo = () => {
    //   // let logoSrc = getWindowLocation() + "/logo_black.jpeg";
    //   // if (store.state.userModule.user.branding_Id == 2) {
    //   //   logoSrc = getWindowLocation() + "/logo_brunkhorst.png";
    //   // }
    //   // return logoSrc;
    // };

    const getBrandingLogo = computed(() => store.getters["themeModule/logoUrl"]);

    const menuItems = [
      {
        name: "Einstellungen",
        id: "/settings",
      },
      {
        name: "Abmelden",
        id: "#",
        onClick: onLogout,
      },
    ];

    return {
      onLogout,
      email,
      dropdownOpen,
      onClickAway,
      menuItems,
      goTo,
      getBrandingLogo,
    };
  },
});
