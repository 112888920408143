
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const canManageUsers = computed(() =>
      store.getters['userModule/isUserAllowedToUseByRoleId']([5, 6, 7])
    );

    //myschaden und schlienz sind nur erlaubt!
    const toursUser = computed(() =>
      store.getters['userModule/isUserAllowedToUseByBrandingId']([1, 38])
    );

    const disabledAlert = () => {
      store.dispatch('genericModule/triggerAlert', {
        alertMessage:
          'Diese Funktion steht Ihrer Nutzer-Rolle nicht zur Verfügung!',
        alertBadgeText: 'disabled',
        alertColor: 'red',
        alertCloseTimeInMs: 2500,
      });
    };

    return {
      canManageUsers,
      disabledAlert,
      toursUser,
    };
  },
});
