
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "loading",
  props: {
    message: {
      type: String,
      default: "Bitte warten...",
    },
    animated: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();

    return {
      logo: computed(() => store.getters["themeModule/loadingLogoByTheme"]),
    };
  },
});
