<template>
  <transition name="bounce">
    <div
      class="
        fixed
        top-3
        p-2
        items-center
        text-indigo-100
        leading-none
        lg:rounded-full
        flex
        lg:inline-flex
        fixed-center
        z-50
      "
      :class="`bg-${color}-800`"
      role="alert"
    >
      <!-- <span
        class="flex rounded-full uppercase px-2 py-1 text-xs font-bold mr-3"
        :class="`bg-${color}-500`"
        >{{ badgeText }}</span
      > -->
      <!-- <span
        class="font-semibold mr-2 text-left flex-auto"
        :class="`text-${size}`"
        >{{ message }}</span
      > -->
      <!-- <button @click="close">
        <svg
          class="fill-current opacity-75 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
          />
        </svg>
      </button> -->
      <span
        class="flex rounded-full uppercase px-2 py-1 text-lg font-bold mr-3"
        :class="`bg-${color}-500`"
        >{{ badgeText }}</span
      >
      <!-- TODO(ngjoni): alertSize (size) not working -->
      <span
        class="font-semibold mr-2 text-left flex-auto"
        :class="`text-3xl`"
        >{{ message }}</span
      >
      <button @click="close">
        <svg
          class="fill-current opacity-75 h-8 w-8"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
          />
        </svg>
      </button>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    message: {
      type: String,
    },
    badgeText: {
      type: String,
    },
    color: {
      type: String,
      default: "green",
    },
    size: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();

    const close = () => {
      store.commit("genericModule/setShowAlert", false);
    };

    return { close };
  },
});
</script>

<style scoped>
.fixed-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>