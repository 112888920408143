export type User = {
    id: string
    mail: string
    role_Id: number;
    branding_Id: number;
    firstName: string;
    lastName: string;

    AppraiserBaseData?: AppraiserBaseData;
    LawyerBaseData?: LawyerBaseData;
    WorkshopBaseData?: WorkshopBaseData;
}

export type Token = {
    token: string
}

export type LoginPayload = {
    Mail: string
    Password: string
}

export const UserRoles = [
    { id: 1, name: 'Super-Admin' },
    { id: 2, name: 'Branding-Admin' },
    { id: 3, name: 'Intertax-Sachbearbeiter' },
    { id: 4, name: 'Branding-Sachbearbeiter' },
    { id: 5, name: 'Rechtsanwalt' },
    { id: 6, name: 'Gutachter' },
    { id: 7, name: 'Werkstatt' },
]

export type CreateUserDTO = {
    Branding_Id: number;
    Role_Id: number;
    Mail: string;
    FirstName: string;
    LastName: string;
    Password: string;

    AppraiserBaseData?: AppraiserBaseData;
    LawyerBaseData?: LawyerBaseData;
    WorkshopBaseData?: WorkshopBaseData;
}
export type AppraiserBaseData = {
    Id: number;
    User_Id: number;
    AppraiserName: string;
    Company: string;
    Mail: string;
    Phone: string;
    DisplayName?: string;
}

export type LawyerBaseData = {
    Id: number;
    User_Id: number;
    CompanyName: string;
    Salutation: string;
    FirstName: string;
    LastName: string;
    StreetAndNumber: string;
    ZipCode: string;
    City: string;
    Phone: string;
    Mail: string;
}
export type WorkshopBaseData = {
    Id: number;
    User_Id: number;
    CompanyName: string;
    StreetAndNumber: string;
    ZipCode: string;
    City: string;
    WorkshopLocation: string;
    ContactName: string;
    ContactPhoneNumber: string;
    ContactEmail: string;
    DisplayName?: string;
}