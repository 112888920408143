
import { defineComponent, onMounted, shallowRef } from "vue";
import { useStore } from "vuex";
import Alert from "@/components/Alert.vue";
import LayoutDefault from "./layouts/LayoutDefault.vue";
import { DEFAULT_THEME } from "./themes";

export default defineComponent({
  components: { Alert, LayoutDefault },
  setup() {
    const layout = shallowRef<any>("div");
    const store = useStore();

    onMounted(() => {
      if (store.getters['userModule/isSignedIn']) return;
      store.dispatch("themeModule/applyNewTheme", DEFAULT_THEME);
    });

    const setLayout = (component: any) => {
      layout.value = component;
    };

    return {
      layout,
      setLayout,
      store,
    };
  },
});
