import { Module, ActionContext } from "vuex";
import { RootState, store } from ".";
import { LawyerBaseData, AppraiserBaseData, WorkshopBaseData } from "@/classes/serviceProvider";
import { ServiceProviderService } from "@/services";

export type ServiceProviderState = {
  [key: string]: any;
  lawyerList: LawyerBaseData[],
  appraiserList: AppraiserBaseData[],
  workshopList: WorkshopBaseData[],
  lawyerBaseData: LawyerBaseData,
  appraiserBaseData: AppraiserBaseData,
  workshopBaseData: WorkshopBaseData
}

const serviceProvider: Module<ServiceProviderState, RootState> = {
  namespaced: true,
  state: () => ({
    lawyerList: [] as LawyerBaseData[],
    appraiserList: [] as AppraiserBaseData[],
    workshopList: [] as WorkshopBaseData[],
    lawyerBaseData: new LawyerBaseData,
    appraiserBaseData: new AppraiserBaseData,
    workshopBaseData: new WorkshopBaseData
  }),
  getters: {
    lawyerListByNameAndId(state: ServiceProviderState) {
      return state.lawyerList.map((element) => {
        return {
          name: element.companyName,
          id: element.id
        }
      }).sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      })
    },
    appraiserListByNameAndId(state: ServiceProviderState) {
      return state.appraiserList.map((element) => {
        return {
          name: element.appraiserName,
          id: element.id
        }
      }).sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      })
    },
    workshopListByNameAndId(state: ServiceProviderState) {
      return state.workshopList.map((element) => {
        return {
          name: element.companyName,
          id: element.id
        }
      }).sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      })
    }
  },
  mutations: {
    setLawyerList(state: ServiceProviderState, list: LawyerBaseData[]) {
      state.lawyerList = list;
    },
    setAppraiserList(state: ServiceProviderState, list: AppraiserBaseData[]) {
      state.appraiserList = list;
    },
    setWorkshopList(state: ServiceProviderState, list: WorkshopBaseData[]) {
      state.workshopList = list;
    },
    setLawyerBaseData(state: ServiceProviderState, baseData: LawyerBaseData) {
      state.lawyerBaseData = baseData;
    },
    setAppraiserBaseData(state: ServiceProviderState, baseData: AppraiserBaseData) {
      state.appraiserBaseData = baseData;
    },
    setWorkshopBaseData(state: ServiceProviderState, baseData: WorkshopBaseData) {
      state.workshopBaseData = baseData;
    },
    setLawyerBaseDataByIdFromList(state: ServiceProviderState, id: number) {
      state.lawyerBaseData = state.lawyerList.filter((e) => e.id == id)[0]
    },
    setAppraiserBaseDataByIdFromList(state: ServiceProviderState, id: number) {
      state.appraiserBaseData = state.appraiserList.filter((e) => e.id == id)[0]
    },
    setWorkshopBaseDataByIdFromList(state: ServiceProviderState, id: number) {
      state.workshopBaseData = state.workshopList.filter((e) => e.id == id)[0]
    },
    resetLawyerBaseData(state: ServiceProviderState) {
      state.lawyerBaseData = new LawyerBaseData;
    },
    resetAppraiserBaseData(state: ServiceProviderState) {
      state.appraiserBaseData = new AppraiserBaseData;
    },
    resetWorkshopBaseData(state: ServiceProviderState) {
      state.workshopBaseData = new WorkshopBaseData;
    },
    resetBaseData(state: ServiceProviderState) {
      state.lawyerBaseData = new LawyerBaseData;
      state.appraiserBaseData = new AppraiserBaseData;
      state.workshopBaseData = new WorkshopBaseData;
    },
  },
  actions: {
    async fetchLawyerList(context: ActionContext<ServiceProviderState, RootState>) {
      const [response, error] = await ServiceProviderService.getAllLawyerBaseData();
      if (!error) {
        context.commit("setLawyerList", response);
        return response
      }
    },
    async fetchAppraiserList(context: ActionContext<ServiceProviderState, RootState>) {
      const [response, error] = await ServiceProviderService.getAllAppraiserBaseData();
      if (!error) {
        context.commit("setAppraiserList", response);
        return response
      }
    },
    async fetchWorkshopList(context: ActionContext<ServiceProviderState, RootState>) {
      const [response, error] = await ServiceProviderService.getAllWorkshopBaseData();
      if (!error) {
        context.commit("setWorkshopList", response);
        return response
      }
    },
    async getLawyerById(context: ActionContext<ServiceProviderState, RootState>, id: number) {
      const [response, error] = await ServiceProviderService.getLawyerById(id);
      
      if (!error) {
        context.commit("setLawyerBaseData", response);
        return response
      }
    },
    async getAppraiserById(context: ActionContext<ServiceProviderState, RootState>, id: number) {
      const [response, error] = await ServiceProviderService.getAppraiserById(id);
      
      if (!error) {
        context.commit("setAppraiserBaseData", response);
        return response
      }
    },
    async getWorkshopById(context: ActionContext<ServiceProviderState, RootState>, id: number) {
      const [response, error] = await ServiceProviderService.getWorkshopById(id);
      if (!error) {
        context.commit("setAppraiserBaseData", response);
        return response
      }
    },
    async fetchAllListsIfNeeded(context: ActionContext<ServiceProviderState, RootState>) {
      if(context.state.lawyerList.length == 0) {
        context.dispatch("fetchLawyerList")
      }
      if(context.state.appraiserList.length == 0) {
        context.dispatch("fetchAppraiserList")
      }
      if(context.state.workshopList.length == 0) {
        context.dispatch("fetchWorkshopList")
      }
    }
  }
}

export default serviceProvider;
