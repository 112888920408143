export default {
    primary: '#b81c19',
    primaryDark: '#5c0d0c',
    blue: '#18007a',
    green: '#00521a',
    background: '#1c1c1c',
    backgroundGrey: '#3c3c3b',
    dropdownBlue: '#1b212d',
    inputBackground: '#000',
    inputTextColor: '#ffffff',
    inputBorderColor: '#dbdbdb',

    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/myschaden24-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/myschaden24-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/myschaden24-logo.png',

    availableProducts: ["Schadenmeldung", "smartKV", "Reparaturnachweis", "Zustandsbericht", "FloorCheckGO", "PreCheckGO", "Haftpflichtschaden", "Kaskoschaden", "Teilkaskoschaden", "Fahrzeugbewertung", "Kostenvoranschlag", "MWGA", "Tours"],
};