import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_loading = _resolveComponent("loading")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_layout_default = _resolveComponent("layout-default")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.store.state.genericModule.showAlert)
      ? (_openBlock(), _createBlock(_component_Alert, {
          key: 0,
          message: _ctx.store.state.genericModule.alertMessage,
          badgeText: _ctx.store.state.genericModule.alertBadgeText,
          color: _ctx.store.state.genericModule.alertColor
        }, null, 8, ["message", "badgeText", "color"]))
      : _createCommentVNode("", true),
    (_ctx.store.state.userModule.user)
      ? (_openBlock(), _createBlock(_component_layout_default, { key: 1 }, {
          default: _withCtx(() => [
            (_ctx.store.state.userModule.loading)
              ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
              : (_openBlock(), _createBlock(_component_router_view, {
                  key: 1,
                  onLayout: _ctx.setLayout
                }, null, 8, ["onLayout"]))
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.store.state.userModule.loading)
            ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
            : (_openBlock(), _createBlock(_component_router_view, {
                key: 1,
                onLayout: _ctx.setLayout
              }, null, 8, ["onLayout"]))
        ], 64))
  ], 64))
}