import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    primary: '#FDC207',
    primaryDark: '#FDC207',

    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/fraikin-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/fraikin-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/fraikin-logo.png',

    availableProducts: ["Zustandsbericht", "Haftpflichtschaden", "Kaskoschaden"],
});