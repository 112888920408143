import { Module, ActionContext } from "vuex";
import { User, LoginPayload, CreateUserDTO, UserRoles } from "@/types/User"
import { UserService } from "@/services";
import { RootState } from ".";
import { Branding } from "@/types/Branding";

export type ChangePasswordState = {
  message: string;
  result: boolean;
  loading: boolean;
}

export type UserState = {
  user?: User;
  userBranding?: Branding,
  loading: boolean;
  loginError: string;
  resetPasswordMessage: string;
  changePasswordState: ChangePasswordState;
  brandings: Branding[],
}

export function hasRole(role: string, userRole: string) {
  const foundRole = UserRoles.find(r => r.name == role);
  if (foundRole) {
    return foundRole.id.toString() == userRole;
  }
  return false;
}

const user: Module<UserState, RootState> = {
  namespaced: true,
  state: () => ({
    user: undefined,
    userBranding: undefined,
    loading: false,
    loginError: "",
    resetPasswordMessage: "",
    changePasswordState: {
      message: '',
      result: false,
      loading: false,
    },
    brandings: [],
  }),
  getters: {
    email: (state: UserState) => state.user?.mail ?? '',
    userBrandingId: (state: UserState) => state.user ? state.user.branding_Id : 1,
    isSignedIn: (state: UserState) => state.user != undefined,
    userRole: (state: UserState) => state.user?.role_Id ?? -1,
    isUserAllowedToUseByRoleId: (state: UserState) => (blockedRoleId: number[]): boolean => {
      const results = <boolean[]>[]

      blockedRoleId.forEach(id => {
        if (state.user?.role_Id == id) {
          results.push(true)
        } else {
          results.push(false)
        }
      })
    return results.filter((r) => r == true).length == 0
    },
    isUserAllowedToUseByBrandingId: (state: UserState) => (allowedBrandingId: number[]): boolean => { 
      const results = <boolean[]>[]

      allowedBrandingId.forEach(id => {
        if (state.user?.branding_Id == id) {
          results.push(true)
        } else {
          results.push(false)
        }
      })

      return results.filter((r) => r == true).length != 0
    }
  },
  mutations: {
    setUser(state: UserState, user: User) {
      state.user = user;
    },
    toggleLoading(state: UserState) {
      state.loading == true ? state.loading = false : state.loading = true;
    },
    setLoginError(state: UserState, error: string) {
      state.loginError = error;
    }
  },
  actions: {
    fetchCurrentUser: async (context: ActionContext<UserState, RootState>) => {
      const [user, error] = await UserService.getCurrentUser()

      if (error) {
        context.commit('setUser', undefined)
        return
      }

      // fetch missing brandings
      if (context.state.brandings.length == 0)
        await context.dispatch('fetchBrandings');

      // add user branding
      if (context.state.brandings.length > 0) {
        context.state.userBranding = context.state.brandings.find(b => b.id == user.branding_Id);
        // try to apply new theme
        if (context.state.userBranding)
          context.dispatch("themeModule/applyNewTheme", context.state.userBranding.brandingKey.toLowerCase(), {root:true});
      }

      context.commit('setUser', user)
      UserService.storeUser(user)
    },
    login: async (context: ActionContext<UserState, RootState>, LoginPayload: LoginPayload) => {
      const [token, error] = await UserService.login(LoginPayload);

      if (error) {
        context.commit('setLoginError', 'Die E-Mail oder das Passwort scheinen nicht korrekt zu sein.')
        return
      }

      context.commit('setLoginError', '')
      UserService.storeToken(token.token)

      await context.dispatch('fetchCurrentUser')
    },
    logout: async (context: ActionContext<UserState, RootState>) => {
      UserService.logout()
      context.state.user = undefined
    },
    resetPassword: async (context: ActionContext<UserState, RootState>, mail: string) => {
      await UserService.resetPassword(mail)
      context.state.resetPasswordMessage = 'Es wurde eine E-Mail mit Ihrem neuen Passwort verschickt.'
    },
    changePassword: async (context: ActionContext<UserState, RootState>, options: { password: string, newPassword: string, confirmPassword: string }) => {
      context.state.changePasswordState.loading = true;

      const [_, error] = await UserService.changePassword(options.password, options.newPassword, options.confirmPassword)

      if (error) {
        context.state.changePasswordState.message = 'Es ist ein Fehler aufgetreten.'
        context.state.changePasswordState.result = false
      } else {
        context.state.changePasswordState.message = 'Ihr Passwort wurde erfolgreich geändert.'
        context.state.changePasswordState.result = true
      }

      context.state.changePasswordState.loading = false;
    },
    fetchBrandings: async (context: ActionContext<UserState, RootState>) => {
      const [response, error] = await UserService.getAllBrandings();

      if (!error) {
        context.state.brandings = response;
      }
    },
    fetchUsersForBranding: async (_, id: number): Promise<User[]> => {
      const [response, error] = await UserService.getAllBrandingUsersById(id);
      if (!error) return response;
      else return [];
    },
    createUserForBranding: async (_, data: CreateUserDTO): Promise<boolean> => {
      const [__, error] = await UserService.createUser(data);
      if (!error) return true;
      return false;
    },
    deleteUserById: async (_, options: { userId: number, brandingId?: number }) => {
      const [__, error] = await UserService.deleteUser(options.userId, options.brandingId);
      if (!error) return true;
      return false;
    },
  },
}

export default user;