import { DEFAULT_LOADING_LOGO, DEFAULT_LOGO, DEFAULT_SMALL_LOGO } from "@/store/theme";
import { DEFAULT_THEME, themes } from "."

export interface ITheme {
    [key: string]: string | string[];
}

export interface IThemes {
    [key: string]: ITheme;
}

export interface IMappedTheme {
    [key: string]: string | string[] | null;
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
    return {
        '--primary-color': variables.primary,
        '--primary-color-dark': variables.primaryDark,
        '--myschaden-blue': variables.blue,
        '--myschaden-green': variables.green,
        '--background-color': variables.background,
        '--background-grey': variables.backgroundGrey,
        '--dropdown-blue': variables.dropdownBlue,
        '--input-background-color': variables.inputBackground,
        '--input-text-color': variables.inputTextColor,
        '--input-border-color': variables.inputBorderColor,
        'logoUrl': variables.logoUrl ?? DEFAULT_LOGO,
        'smallLogoUrl': variables.smallLogoUrl ?? DEFAULT_SMALL_LOGO,
        'loadingLogoUrl': variables.loadingLogoUrl ?? DEFAULT_LOADING_LOGO,
        'availableProducts': variables.availableProducts ?? [],
    }
};

export const applyTheme = (theme: string): IMappedTheme => {
    let themeObj: IMappedTheme;

    try {
        themeObj = mapTheme(themes[theme]);
    }
    catch {
        themeObj = mapTheme(themes[DEFAULT_THEME]);
    }

    const root = document.documentElement;

    Object.keys(themeObj).forEach((prop) => {
        if (prop === 'name' || prop === 'logoLink' || prop === 'availableProducts') return;
        root.style.setProperty(prop, themeObj[prop]!.toString());
    });

    return themeObj;
};

export function extendTheme(
    extending: ITheme,
    newTheme: ITheme
): ITheme {
    return { ...extending, ...newTheme };
}