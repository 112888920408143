import { AxiosHttpClient } from "@/modules/axios_http_client";
import { ApiResponse } from "@/modules/http_client";
import { TourResponse } from "@/types/Tour";
import { AxiosInstance } from "axios";

class TourService {
    private httpClient: AxiosHttpClient = new AxiosHttpClient()

    async fetchStoredTours(branding: string): Promise<ApiResponse<TourResponse | undefined, Error>> {
        const instance = this.httpClient.secondApiClient()
        const [error, response] = await this.httpClient.get<TourResponse>(`/tours/all`, instance);
        return [response, error]
    }

    async fetchOneStoredTour(id: string): Promise<ApiResponse<TourResponse | undefined, Error>> {
        const instance = this.httpClient.secondApiClient("default")
        const [error, response] = await this.httpClient.get<TourResponse>(`/tours/one/${id}`, instance);
        return [response, error]
    }
}

export default new TourService()