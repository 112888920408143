import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex-initial h-15 flex items-center justify-between bg-background w-full pr-5" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-base" }
const _hoisted_4 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_GenericDropdown = _resolveComponent("GenericDropdown")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.getBrandingLogo,
          class: "w-36 cursor-pointer p-4"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createVNode(_component_GenericDropdown, {
      items: _ctx.menuItems,
      onSetSelected: _ctx.goTo,
      highlightColor: "red",
      backgroundColor: "grey",
      isMenu: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, [
          _createTextVNode("Moin, "),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.email), 1)
        ])
      ]),
      _: 1
    }, 8, ["items", "onSetSelected"])
  ])), [
    [_directive_click_away, _ctx.onClickAway]
  ])
}