import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    //Individuelle Farbwerte
    primary: '#5E99C9',
    primaryDark: '#5E99C9',

    //Hier kommen die hinzugefügte Logos rein
    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/bfe-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/bfe-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/bfe-logo.png',

    //Hier die spezifische Produkte eintragen
    availableProducts: ["Schadenmeldung"],
});