import CommissioningService, { BaseDataType } from "@/services/CommissioningService";
import { AppraiserBaseData, WorkshopBaseData } from "@/classes/serviceProvider";
import { ActionContext, Module } from "vuex";
import { RootState } from ".";
import { Commissioning, CustomBaseData } from "@/types/Commissioning";

type CreateBaseData = {
    result: boolean
    message: string
    loading: boolean
}

export type CommissioningState = {
    [key: string]: any;

    workshops: WorkshopBaseData[],
    selectedWorkshop?: WorkshopBaseData,

    appraiser: AppraiserBaseData[],
    selectedAppraiser?: AppraiserBaseData,

    selectedCustomData?: CustomBaseData,

    commissioning?: Commissioning,

    readonly: boolean,
    onChange: boolean,

    loading: boolean,

    createAppraiser: CreateBaseData,
    createWorkshop: CreateBaseData,
}

const report: Module<CommissioningState, RootState> = {
    namespaced: true,
    state: () => ({
        workshops: [],
        appraiser: [],
        onChange: false,
        readonly: false,
        loading: false,
        createAppraiser: {
            result: false,
            loading: false,
            message: "",
        },
        createWorkshop: {
            result: false,
            loading: false,
            message: "",
        }
    }),
    getters: {
        appraisers: (state: CommissioningState) => state.appraiser.filter(a => a.user_Id == 0 || a.user_Id == null),
        workshops: (state: CommissioningState) => state.workshops.filter(w => w.user_Id == 0 || w.user_Id == null),
        loading: (state: CommissioningState) => state.loading,
        workshopListByNameAndId(state: CommissioningState) {
            const result = state.workshops.map((e) => {
              return {
                name: e.displayName != null && e.displayName.length == 0 ? e.companyName : e.displayName,
                id: e.id
              }
            }).sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
      
              return 0;
            })
            result.splice(0, 0, { name: 'Werkstatt auswählen', id: -1})
            return result
          },
          appraiserListByNameAndId(state: CommissioningState) {
            const result = state.appraiser.map((e) => {
                return {
                    name: e.displayName != null && e.displayName.length == 0 ? e.appraiserName : e.displayName,
                    id: e.id
                  }
            }).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
        
                return 0;
              })

              result.splice(0, 0, { name: 'Gutachter auswählen', id: -1})
              return result
          },
          readonly(state: CommissioningState) {
            return state.readonly
          }
    },
    mutations: {
        setSelectedWorkshop: (state: CommissioningState, id: number) => {
            if (id == -1) {
                state.readonly = false;
                state.selectedWorkshop = undefined;
            }
            else {
                state.selectedWorkshop = state.workshops.find(w => w.id == id);
                state.readonly = true;
            }
        },
        setSelectedAppraiser: (state: CommissioningState, id: number) => {
            if (id == -1) {
                state.readonly = false;
                state.selectedAppraiser = undefined;
            } else {
                state.readonly = true;
                state.selectedAppraiser = state.appraiser.find(a => a.id == id);
            }
        },
        toggleChangeStatus: (state: CommissioningState) => state.onChange = true,
    },
    actions: {
        async fetchCommissioningForReport(context: ActionContext<CommissioningState, RootState>, reportId: number) {
            if (context.state.onChange) return;

            const [response, error] = await CommissioningService.fetchForReportId(reportId);
            if (!error && response) {
                context.state.commissioning = response;

                if (response.workshop) {
                    await context.dispatch('fetchWorkshopList');

                    context.state.selectedWorkshop = response.workshop;
                    context.commit("reportModule/setReportValueByType", {
                        propertyName: 'commissioningWorkshopBaseDataId',
                        value: response.baseId,
                    }, {root:true});

                    context.commit("reportModule/setReportValueByType", {
                        propertyName: 'commissioningTypeId',
                        value: response.baseId,
                    }, {root:true});

                    await context.dispatch("updateWorkshopValuesInStore");
                } else if (response.appraiser) {
                    await context.dispatch('fetchAppraiserList');

                    context.state.selectedAppraiser = response.appraiser;
                    context.commit("reportModule/setReportValueByType", {
                        propertyName: 'commissioningAppraiserBaseDataId',
                        value: response.baseId,
                    }, {root:true});

                    context.commit("reportModule/setReportValueByType", {
                        propertyName: 'commissioningTypeId',
                        value: response.baseId,
                    }, {root:true});

                    await context.dispatch("updateAppraiserValuesInStore");
                } else if (response.customData) {
                    context.state.selectedCustomData = response.customData;
                    await context.dispatch("updateCustomDataValuesInStore");
                }

                context.commit("reportModule/setReportValueByType", {
                    propertyName: 'selectedCommissioningType',
                    value: response.baseType == 'workshop' ? 1 : response.baseType == 'insurant' ? 2 : response.baseType == 'appraiser' ? 3 : response.baseType == 'custom' ? 4 : -1,
                }, {root: true});
                context.commit("reportModule/setReportValueByType", {
                    propertyName: 'commissioningType',
                    value: response.baseType,
                }, {root:true});
                context.commit("reportModule/setReportValueByType", {
                    propertyName: 'commissioningDamageDescription',
                    value: response.damageDescription,
                }, {root:true});
            } else {
                context.state.commissioning = undefined;
            }
        },
        async fetchWorkshopList(context: ActionContext<CommissioningState, RootState>) {
            context.state.loading = true;
            const [response, error] = await CommissioningService.fetchWorkshopList();

            if (!error) {
                context.state.workshops = response;
            } else {
                context.state.workshops = [];
            }
            context.state.loading = false;
        },
        async updateWorkshopValuesInStore(context: ActionContext<CommissioningState, RootState>) {
            if (context.state.selectedWorkshop?.user_Id && context.state.selectedWorkshop.user_Id > 0) {
                context.commit("reportModule/setReportValueByType", {
                    propertyName: 'commissioningWorkshopBaseUserId',
                    value: context.state.selectedWorkshop.user_Id,
                }, {root:true});
            } else {
                context.commit("reportModule/setReportValueByType", {
                    propertyName: 'commissioningWorkshopBaseUserId',
                    value: 0,
                }, {root:true});
            }

            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningWorkshopName',
                value: context.state.selectedWorkshop?.companyName ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningWorkshopContactName',
                value: context.state.selectedWorkshop?.contactName ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningWorkshopStreetAndNumber',
                value: context.state.selectedWorkshop?.streetAndNumber ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningWorkshopZipCode',
                value: context.state.selectedWorkshop?.zipCode ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningWorkshopCity',
                value: context.state.selectedWorkshop?.city ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningWorkshopLocation',
                value: context.state.selectedWorkshop?.workshopLocation ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningWorkshopPhone',
                value: context.state.selectedWorkshop?.contactPhoneNumber ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningWorkshopMail',
                value: context.state.selectedWorkshop?.contactEmail ?? "",
            }, {root:true});
        },
        async fetchAppraiserList(context: ActionContext<CommissioningState, RootState>) {
            context.state.loading = true;
            const [response, error] = await CommissioningService.fetchAppraiserList();

            if (!error) {
                context.state.appraiser = response;
            } else {
                context.state.appraiser = [];
            }
            context.state.loading = false;
        },
        async updateAppraiserValuesInStore(context: ActionContext<CommissioningState, RootState>) {
            if (context.state.selectedAppraiser?.user_Id && context.state.selectedAppraiser.user_Id > 0) {
                context.commit("reportModule/setReportValueByType", {
                    propertyName: 'commissioningAppraiserBaseUserId',
                    value: context.state.selectedAppraiser.user_Id,
                }, {root:true});
            } else {
                context.commit("reportModule/setReportValueByType", {
                    propertyName: 'commissioningAppraiserBaseUserId',
                    value: 0,
                }, {root:true});
            }

            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningAppraiserName',
                value: context.state.selectedAppraiser?.appraiserName ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningAppraiserCompany',
                value: context.state.selectedAppraiser?.company ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningAppraiserMail',
                value: context.state.selectedAppraiser?.mail ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningAppraiserPhone',
                value: context.state.selectedAppraiser?.phone ?? "",
            }, {root:true});
        },
        async updateCustomDataValuesInStore(context: ActionContext<CommissioningState, RootState>) {
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningCustomName',
                value: context.state.selectedCustomData?.name ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningCustomCompany',
                value: context.state.selectedCustomData?.companyName ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningCustomStreet',
                value: context.state.selectedCustomData?.street ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningCustomZipcode',
                value: context.state.selectedCustomData?.zipcode ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningCustomCity',
                value: context.state.selectedCustomData?.city ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningCustomPhone',
                value: context.state.selectedCustomData?.phone ?? "",
            }, {root:true});
            context.commit("reportModule/setReportValueByType", {
                propertyName: 'commissioningCustomMail',
                value: context.state.selectedCustomData?.mail ?? "",
            }, {root:true});
        },

        async createAppraiser(context: ActionContext<CommissioningState, RootState>, appraiser: AppraiserBaseData) {
            if (appraiser.displayName.length == 0) {
                context.state.createAppraiser.message = "Der Anzeigename muss ausgefüllt werden."
                context.state.createAppraiser.result = false
                return;
            }

            context.state.createAppraiser.loading = true;

            const [response, error] = await CommissioningService.createAppraiser(appraiser);

            if (!error) {
                context.state.createAppraiser.message = "Der Gutachter wurde erfolgreich erstellt."
                context.state.createAppraiser.result = true

                context.state.appraiser.push(response)
            } else {
                context.state.createAppraiser.message = error.message
                context.state.createAppraiser.result = false
            }

            context.state.createAppraiser.loading = false;
        },

        async createWorkshop(context: ActionContext<CommissioningState, RootState>, workshop: WorkshopBaseData) {
            if (workshop.displayName.length == 0) {
                context.state.createWorkshop.message = "Der Anzeigename muss ausgefüllt werden."
                context.state.createWorkshop.result = false
                return;
            }

            context.state.createWorkshop.loading = true;

            const [response, error] = await CommissioningService.createWorkshop(workshop);

            if (!error) {
                context.state.createWorkshop.message = "Die Werkstatt wurde erfolgreich erstellt."
                context.state.createWorkshop.result = true

                context.state.workshops.push(response)
            } else {
                context.state.createWorkshop.message = error.message
                context.state.createWorkshop.result = false
            }

            context.state.createWorkshop.loading = false;
        },

        async deleteBaseData(context: ActionContext<CommissioningState, RootState>, options: {type: BaseDataType, id: number}) {
            const [_, error] = await CommissioningService.deleteBaseData(options.type, options.id);
            if (!error) {
                if (options.type == 'appraiser') {
                    const appraiser = context.state.appraiser.find(a => a.id == options.id);
                    if (appraiser) {
                        const index = context.state.appraiser.indexOf(appraiser);
                        context.state.appraiser.splice(index, 1);
                    }
                } else {
                    const workshop = context.state.workshops.find(w => w.id == options.id);
                    if (workshop) {
                        const index = context.state.workshops.indexOf(workshop);
                        context.state.workshops.splice(index, 1);
                    }
                }

                const type = options.type == 'appraiser' ? 'Der Gutachter' : 'Die Werkstatt';

                await context.dispatch("genericModule/triggerAlert", {
                    alertMessage: `${type} wurde erfolgreich gelöscht!`,
                    alertBadgeText: "disabled",
                    alertColor: "green",
                    alertCloseTimeInMs: 2500,
                }, {root: true});
            } else {
                await context.dispatch("genericModule/triggerAlert", {
                    alertMessage: "Leider ist ein Fehler aufgetreten!",
                    alertBadgeText: "disabled",
                    alertColor: "green",
                    alertCloseTimeInMs: 2500,
                }, {root: true});
            }
        }
    }
}

export default report;