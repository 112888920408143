import { AxiosHttpClient } from "@/modules/axios_http_client";
import { ApiResponse } from "@/modules/http_client";
import { VehicleManufacturer, VehicleModel, VehicleType } from "@/types/Vehicle";

class VehicleService {
    private httpClient: AxiosHttpClient = new AxiosHttpClient()

    async getVehicleTypes(): Promise<ApiResponse<Array<VehicleType>, Error>> {
        const [error, response] = await this.httpClient.get<Array<VehicleType>>(`/vehicledata/getvehicletypes`)
        return [response!, error]
    }

    async getVehicleManufacturersByType(typeId: number): Promise<ApiResponse<Array<VehicleManufacturer>, Error>> {
        const [error, response] = await this.httpClient.get<Array<VehicleManufacturer>>(`/vehicledata/GetVehicleManufacturersForVehicleTypeId/${typeId}`)
        return [response!, error]
    }

    async getVehicleModels(manufacturerId: number, typeId: number): Promise<ApiResponse<Array<VehicleModel>, Error>> {
        const [error, response] = await this.httpClient.get<Array<VehicleModel>>(`/vehicledata/GetVehicleModelsForVehicleManufacturerId/${manufacturerId}/AndTypeId/${typeId}`)
        return [response!, error]
    }
}

export default new VehicleService()
