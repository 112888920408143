import { AxiosHttpClient } from "@/modules/axios_http_client";
import { ApiResponse } from "@/modules/http_client";
import { WorkshopBaseData, AppraiserBaseData } from "@/classes/serviceProvider";
import { Commissioning, CustomBaseData } from "@/types/Commissioning";

export type BaseDataType = "appraiser" | "workshop";

class CommissioningService {
    private httpClient: AxiosHttpClient = new AxiosHttpClient()

    async fetchWorkshopList(): Promise<ApiResponse<WorkshopBaseData[], Error>> {
        const [error, response] = await this.httpClient.get<WorkshopBaseData[]>(`/commissioning/workshops`);
        return [response!, error];
    }

    async fetchAppraiserList(): Promise<ApiResponse<AppraiserBaseData[], Error>> {
        const [error, response] = await this.httpClient.get<AppraiserBaseData[]>(`/commissioning/appraiser`);
        return [response!, error];
    }

    async fetchForReportId(reportId: number): Promise<ApiResponse<Commissioning, Error>> {
        const [error, response] = await this.httpClient.get<Commissioning>(`/commissioning/${reportId}`);
        return [response!, error]
    }

    async createAppraiser(appraiser: AppraiserBaseData): Promise<ApiResponse<AppraiserBaseData, Error>> {
        const [error, response] = await this.httpClient.post<AppraiserBaseData>(`/commissioning/appraiser`, appraiser);
        return [response!, error];
    }

    async createWorkshop(workshop: WorkshopBaseData): Promise<ApiResponse<WorkshopBaseData, Error>> {
        const [error, response] = await this.httpClient.post<WorkshopBaseData>(`/commissioning/workshop`, workshop);
        return [response!, error];
    }

    async deleteBaseData(type: BaseDataType, id: number): Promise<ApiResponse<string, Error>> {
        const [error, response] = await this.httpClient.delete<string>(`/commissioning/${type}/${id}`);
        return [response!, error];
    }
}

export default new CommissioningService();