import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    //Individuelle Farbwerte
    primary: '#1c86c8',
    primaryDark: '#1c86c8',

    //Hier kommen die hinzugefügte Logos rein
    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/toha-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/toha-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/toha-logo.png',

    //Hier die spezifische Produkte eintragen
    availableProducts: ["Zustandsbericht"],
});