import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    primary: '#3054a7',
    primaryDark: '#274385',

    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/salomon-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/salomon-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/salomon-logo.png',

    availableProducts: ["smartKV", "FloorCheckGo"],
});