import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    primary: '#FBB51D',
    primaryDark: '#b5841b',
    background: '#1b1b1b',

    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/brunkhorst-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/brunkhorst-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/brunkhorst-logo.png',

    availableProducts: ["Haftpflichtschaden", "Kaskoschaden", "Teilkaskoschaden"],
});