import VehicleService from "@/services/VehicleService";
import { VehicleManufacturer, VehicleModel, VehicleType } from "@/types/Vehicle";
import { ActionContext, Module } from "vuex";
import { RootState } from ".";

interface StepState {
    stepNum: number;
    vehicleTypes: Array<VehicleType>,
    vehicleManufacturers: Array<VehicleManufacturer>,
    vehicleModels: Array<VehicleModel>,
    selectedType: number;
    selectedManufacturer: number;
    watchVehicleModels: boolean;
}

export type VehicleState = {
    currentStep: number;
    loading: boolean;
    stepStates: StepState[];
}

const vehicle: Module<VehicleState, RootState> = {
    namespaced: true,
    state: () => ({
        stepStates: [],
        currentStep: 0,
        loading: false,
    }),
    getters: {
        types: (state: VehicleState) => state.stepStates.find((s) => s.stepNum == state.currentStep)?.vehicleTypes ?? [],
        manufacturers: (state: VehicleState) => state.stepStates.find((s) => s.stepNum == state.currentStep)?.vehicleManufacturers ?? [],
        models: (state: VehicleState) => state.stepStates.find((s) => s.stepNum == state.currentStep)?.vehicleModels ?? [],
        loading: (state: VehicleState) => state.loading,
        selectedType: (state: VehicleState) => state.stepStates.find((s) => s.stepNum == state.currentStep)?.selectedType ?? -1,
        selectedManufacturer: (state: VehicleState) => state.stepStates.find((s) => s.stepNum == state.currentStep)?.selectedManufacturer ?? -1,
        watchVehicleModels: (state: VehicleState) => state.stepStates.find((s) => s.stepNum == state.currentStep)?.watchVehicleModels ?? false,
    },
    mutations: {
        setManufacturers: (state: VehicleState, { typeId, response }) => {
            const step = state.stepStates.find((s) => s.stepNum == state.currentStep);

            if (step) {
                step.selectedType = typeId;
                step.vehicleManufacturers = response;
            }
        },
        toggleLoading: (state: VehicleState) => {
            state.loading = !state.loading
        },
        setCurrentStepNum: (state: VehicleState, stepNum: number) => {
            if (state.stepStates.filter((item) => item.stepNum == stepNum).length == 0) {
                state.stepStates.push(<StepState>{
                    stepNum: stepNum,
                    vehicleTypes: state.stepStates[0].vehicleTypes,
                    vehicleManufacturers: [],
                    vehicleModels: [],
                    selectedType: -1,
                    selectedManufacturer: -1,
                    watchVehicleModels: false,
                });
            }
            state.currentStep = stepNum;
        }
    },
    actions: {
        async initModule(context: ActionContext<VehicleState, RootState>, stepNum?: number) {
            context.commit('toggleLoading')
            context.state.currentStep = (stepNum) ? stepNum : 0;
            context.state.stepStates = [];
            const [response, error] = await VehicleService.getVehicleTypes()

            context.commit('toggleLoading')

            if (!error) {
                context.state.stepStates.push(<StepState>{
                    stepNum: (stepNum) ? stepNum : 0,
                    vehicleTypes: response,
                    vehicleManufacturers: [],
                    vehicleModels: [],
                    selectedManufacturer: -1,
                    selectedType: -1,
                    watchVehicleModels: false,
                });
            }
        },
        async fetchManufacturers(context: ActionContext<VehicleState, RootState>, typeId: number) {
            context.commit('toggleLoading')
            const step = context.state.stepStates.find((s) => s.stepNum == context.state.currentStep);
            if (step)
                step.vehicleManufacturers = []

            const [response, error] = await VehicleService.getVehicleManufacturersByType(typeId)

            context.commit('toggleLoading')

            if (!error) {
                context.commit('setManufacturers', { typeId, response })
            }
        },
        async fetchModels(context: ActionContext<VehicleState, RootState>, manufacturerId: number) {
            const step = context.state.stepStates.find((s) => s.stepNum == context.state.currentStep);
            if (step) {
                step.selectedManufacturer = manufacturerId;
                step.vehicleModels = []
            }

            context.commit('toggleLoading')
            const [response, error] = await VehicleService.getVehicleModels(manufacturerId, step!.selectedType)
            context.commit('toggleLoading')


            if (!error) {
                step!.watchVehicleModels = true;
                step!.selectedManufacturer = manufacturerId;
                step!.vehicleModels = response
            }
        },
        reset(context: ActionContext<VehicleState, RootState>) {
            const step = context.state.stepStates.find((s) => s.stepNum == context.state.currentStep);
            if (step) {
                step.selectedType = -1;
                step.selectedManufacturer = -1;

                step.vehicleManufacturers = [];
                step.vehicleModels = [];
            }
        }
    }
}

export default vehicle