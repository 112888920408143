import { FetchReportFilesResponse, FetchReportsResponse, FetchReportStatusHistoryResponse, ReportFilter } from "@/types/Report";
import { AxiosHttpClient } from "@/modules/axios_http_client";
import { Demand } from "@/classes/demand";

class DemandService {
    private httpClient: AxiosHttpClient = new AxiosHttpClient()

    async addDemandList(newDemandList: Array<Demand>) {
        const jsonWithNull = JSON.stringify(newDemandList).replaceAll('""', 'null');
        const [error, response] = await this.httpClient.post(`/sqlreports/AddDemands`, jsonWithNull)
        return [response, error]
    }

    async fetchDemandListById(report_Id: number) {
        const [error, response] = await this.httpClient.get(`/sqlreports/GetDemandsForReportId/${report_Id}`)
        return [response, error]
    }

    async updateDemandList(updatedDemandList: Array<Demand>) {
        const jsonWithNull = JSON.stringify(updatedDemandList).replaceAll('""', 'null');
        const [response, error] = await this.httpClient.post(`/sqlreports/UpdateDemands`, jsonWithNull)
    }
}

export default new DemandService()
