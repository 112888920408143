import { CreateUserDTO, LoginPayload, Token, User } from "@/types/User";
import { AxiosHttpClient } from "@/modules/axios_http_client";
import { ApiResponse } from "@/modules/http_client";
import { Branding } from "@/types/Branding";

export const AUTH_KEY = 'myschaden_portal'
export const USER_KEY = 'myschaden_user'

class UserService {
    private httpClient: AxiosHttpClient = new AxiosHttpClient()

    storeToken(token: string) {
        window.localStorage.setItem(AUTH_KEY, token)
    }

    getToken(): string {
        return window.localStorage.getItem(AUTH_KEY) ?? '';
    }

    storeUser(user: User) {
        window.localStorage.setItem(USER_KEY, JSON.stringify(user))
    }

    async login(payload: LoginPayload): Promise<ApiResponse<Token, Error>> {
        const [error, token] = await this.httpClient.post<Token>("/userhandling/login", payload)
        return [token!, error]
    }

    async getCurrentUser(): Promise<ApiResponse<User, Error>> {
        const [error, user] = await this.httpClient.get<User>("/userhandling/getCurrentUser")
        return [user!, error]
    }

    async resetPassword(mail: string): Promise<ApiResponse<string, Error>> {
        const [error, response] = await this.httpClient.post<string>(`/userhandling/ResetPassword`, { 'Mail': mail })
        return [response!, error]
    }

    async changePassword(password: string, newPassword: string, confirmPassword: string): Promise<ApiResponse<string, Error>> {
        const [error, response] = await this.httpClient.post<string>(`/userhandling/ChangePassword`, { 'OldPassword': password, 'NewPassword': newPassword, 'NewPasswordCopy': confirmPassword })
        return [response!, error]
    }

    async getAllBrandings(): Promise<ApiResponse<Branding[], Error>> {
        const [error, response] = await this.httpClient.get<Branding[]>(`/brandings/GetAllBrandings`)
        return [response!, error];
    }

    async getAllBrandingUsersById(id: number): Promise<ApiResponse<User[], Error>> {
        const [error, response] = await this.httpClient.get<User[]>(`brandings/GetAllUserForBrandingId/${id}`);
        return [response!, error];
    }

    async createUser(data: CreateUserDTO): Promise<ApiResponse<string, Error>> {
        const [error, response] = await this.httpClient.post<string>(`userhandling/createuser`, data);
        return [response!, error];
    }

    async deleteUser(id: number, brandingId?: number): Promise<ApiResponse<string, Error>> {
        if (!brandingId) {
            const [error, response] = await this.httpClient.delete<string>(`userhandling/DeleteUserWithId/${id}`);
            return [response!, error];
        } else {
            const [error, response] = await this.httpClient.delete<string>(`userhandling/DeleteUserWithId/${id}/${brandingId}`);
            return [response!, error];
        }
    }

    logout() {
        window.localStorage.removeItem(AUTH_KEY)
        window.localStorage.removeItem(USER_KEY)
    }
}

export default new UserService()