import { DEFAULT_THEME, themes } from "@/themes";
import { applyTheme } from "@/themes/utils";
import { ActionContext, Module } from "vuex";
import { RootState } from ".";

export type ThemeState = {
    logoUrl?: string,
    smallLogoUrl?: string,
    loadingLogoUrl?: string,
    currentTheme: string,
    availableProducts: string[],
}

export const DEFAULT_LOGO = "https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/myschaden24.jpeg";
export const DEFAULT_SMALL_LOGO = "https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/myschaden-small.png";
export const DEFAULT_LOADING_LOGO = "https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/myschaden-loading.png";

const theme: Module<ThemeState, RootState> = {
    namespaced: true,
    state: () => ({
        logoUrl: undefined,
        smallLogoUrl: undefined,
        loadingLogoUrl: undefined,
        currentTheme: DEFAULT_THEME,
        availableProducts: [],
    }),
    getters: {
        logoUrl: (state: ThemeState) => state.logoUrl ?? DEFAULT_LOGO,
        smallLogoUrl: (state: ThemeState) => state.smallLogoUrl ?? DEFAULT_SMALL_LOGO,
        availableProducts: (state: ThemeState) => state.availableProducts,
        smallLogoByTheme: (state: ThemeState) => (theme: string) => {
            const themeObj = themes[theme];
            if (!themeObj) return undefined;
            return themeObj.smallLogoUrl as string;
        },
        loadingLogoByTheme: (state: ThemeState) => state.loadingLogoUrl ?? DEFAULT_LOADING_LOGO,
    },
    actions: {
        applyNewTheme: async (context: ActionContext<ThemeState, RootState>, theme: string) => {
            const mappedTheme = applyTheme(theme);

            context.state.currentTheme = theme;
            context.state.logoUrl = mappedTheme.logoUrl!.toString();
            context.state.smallLogoUrl = mappedTheme.smallLogoUrl!.toString();
            context.state.loadingLogoUrl = mappedTheme.loadingLogoUrl!.toString();
            
            let themeObj = themes[theme];
            if (!themeObj) themeObj = themes['base'];

            if (themeObj.availableProducts) {
                context.state.availableProducts = themeObj.availableProducts as string[];
            }
        }
    }
};

export default theme;