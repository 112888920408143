import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    primary: '#006ec8',
    primaryDark: '#00447b',


    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/bob-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/bob-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/bob-logo.png',

    availableProducts: ["Kaskoschaden", "smartKV", "FloorCheckGO", "Haftpflichtschaden"],
});