import base from "./base";
import { extendTheme } from "./utils";

export default extendTheme(base, {
    primary: '#004799',
    primaryDark: '#003673',

    logoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/ernst-digital-logo.png',
    smallLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/ernst-digital-small.png',
    loadingLogoUrl: 'https://intertaxexpertstaging.blob.core.windows.net/frontend-cdn/ernst-digital-logo.png',

    availableProducts: ["Schadenmeldung", "smartKV", "Zustandsbericht", "FloorCheckGO", "Haftpflichtschaden"],
});