import TourService from "@/services/TourService";
import { TourResponse } from "@/types/Tour";
import { ActionContext, Module } from "vuex";
import { RootState } from ".";

export type TourState = {
    [key: string]: any;
    tours?: TourResponse;
    loading: boolean;
    error?: string;
    preTour?: TourResponse;
    currentTour?: TourResponse;
}

const tourModule: Module<TourState, RootState> = {
    namespaced: true,
    state: () => ({
        loading: false,
    }),

    mutations: {
        toggleLoading(state: TourState) {
            state.loading = !state.loading
        },
        setTours(state: TourState, tour: TourResponse) {
            state.tours = tour;
        },
    },

    actions: {
        async fetchStoredChecklists(context: ActionContext<TourState, RootState>, branding: string) {
            context.commit("toggleLoading");

            const [response, error] = await TourService.fetchStoredTours(branding)


            if (error) {
                context.state.error = error.message
            } else {
                context.state.tours = response
            }

            context.commit("toggleLoading");
        },

        async fetchOneStoredChecklist(context: ActionContext<TourState, RootState>, id: string) {
            context.commit("toggleLoading");

            const [response, error] = await TourService.fetchOneStoredTour(id)
            
            if (error) {
                context.state.error = error.message
                context.state.currentTour = undefined
            } else {
                context.state.currentTour = response
            }

            context.commit("toggleLoading");
        },


        async fetchOnePreTourStoredChecklist(context: ActionContext<TourState, RootState>, id: string) { 
            context.commit("toggleLoading");


            const [response, error] = await TourService.fetchOneStoredTour(id)


            if (error) {
                context.state.error = error.message
                context.state.preTour = undefined
            } else {
                context.state.preTour = response
            }

            context.commit("toggleLoading");
        }
    }
}

export default tourModule;